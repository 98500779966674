import Keycloak from "keycloak-js";
import config from "constants/config";

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak({
  realm: "qjumpers",
  url: config.KEYCLOAK.apiHost + config.KEYCLOAK.apiPath,
  clientId: "qjumpers",
  pkceMethod: "S256",
});

export default keycloak;