export const ConstValues = {
  ReportType: {
    REPORTTYPE_CSV: 1,
    REPORTTYPE_DESIGNER: 2,
  },
  GSMReports: {
    CREDITUSED_REPORT: "gsmcreditusedreport",
    TRACKEMAIL_REPORT: "trackemailreport",
  },
  Tags: {
    TAG_UNSUITABLE: "unsuitable",
  },
  TagTypes: {
    TalentPoolCandidate: 0,
    TalentPoolSearchAndMatchProfile: 1,
  },
  ApplicantSource: {
    TALENTPOOL: "talentpool",
    EMPLOYER: "employer",
  },
  DomainVerificationStatus: {
    PENDING: 0,
    FAILED: 1,
    VERIFIED: 2,
  },
  DomainEmailVerificationTypes: {
    EMAIL: 1,
    DOMAIN: 2,
  },
  LicenseType: {
    VOLUME: 0,
    SUBSCRIPTION: 1,
  },
  ProductCategory: {
    JOBBOARD: 1,
    SEARCHANDMATCH: 2,
    EMPLOYEEDOCUMENTS: 4,
    BAMBOOHR: 5,
    INTELLIHR: 6,
  },
  JobInvitationStatus: {
    NOT_SENT: 0,
    PENDING: 1,
    INVITED: 2,
    APPLIED: 3,
  },
  ModuleTypes: {
    SEARCHANDMATCH: 1,
    EMPLOYEEDOCUMENT: 2,
    BAMBOOHR: 3,
    INTELLIHR: 4,
    SMS: 5,
  },
  JobBoardConnectorType: {
    QJUMPERS: 0,
    EQUEST: 1,
    SEEK: 3,
    TRADEME: 4,
    DEFAULT: 10,
  },
  JobBoardBrandType: {
    UNKNOWN: 0,
    LOGO: 1,
    BANNER: 2,
    SQUARE: 3,
    TEMPLATE: 4,
  },
  LocationLevel: {
    TopLocation: 2,
    SubLocation: 3,
    Area: 4,
    State: 5,
  },
  AccountType: {
    CASUAL: 0,
    VOLUME: 1,
    PREPAID: 2,
  },
  JobBoardPackType: {
    DEFAULT: 0,
    FLEXIBLE: 1,
    BRANDED: 2,
    TRADEMEGOLD: 3,
    TRADEMESILVER: 4,
    TRADEMEBRONZE: 5,
  },
  Document: {
    DEFAULT_DOCUMENT_ID: "00000000-0000-0000-0000-000000000000",
  },
  applicationSubStateLables: {
    onboarding_offercreation: "onboarding.offercreation",
    onboarding_agreementsent: "onboarding.agreementsent",
    onboarding_contractreceived: "onboarding.contractreceived",
    onboarding_documentcompleted: "onboarding.documentcompleted",
    Contract_Compilation: "Contract Compilation",
    substatusactions: "substatusactions",
    hired_agreementsent: "hired.agreementsent",
    hired_contractreceived: "hired.contractreceived",
    hired_documentcompleted: "hired.documentcompleted",
    hired_contractcompilation: "hired.contractcompilation",
    onboarding_documentsending: "onboarding.documentsending",
  },
  SystemEmailType: {
    DEFAULT: 0,
    SendApplicationDetail: 23,
  },
  EmailNotifyStatus: {
    SEND: 1,
    NOTIFY: 2,
    DONE: 3,
    FORWARDDETAIL: 4,
  },
  NotificationStatus: {
    Undefined: 0,
    Notify: 1,
    DoNotNotify: 2,
    NotifyLater: 3,
  },
  QuestionTypes: {
    TEXT: "0",
    CHECKBOX: "1",
    RADIO: "2",
    DATE: "3",
  },
  ContractCompilationSubStatus: [
    {
      event: "onboarding.agreementsentEvent",
      option: "Agreement Sent",
    },
  ],
  ContractCompilationReceivedSubStatus: [
    {
      event: "onboarding.contractreceivedEvent",
      option: "Agreement Received",
    },
  ],
  JobBoardGeneral: {
    EMPTYGUID: "00000000-0000-0000-0000-000000000000",
    DRAFT: "draft",
    ACTIVE: "active",
    BULLETLENGTH: 80,
    MAXDAYSLISTING: 30,
  },
  PaymentMethod: {
    CREDITCARD: 0,
    INVOICE: 1,
  },
  LocationCodes: {
    AUSTRALIA: "AU",
    UNITEDKINGDOM: "UK",
    NEWZEALAND: "NZ",
  },
  LocationNames: {
    AUSTRALIA: "Australia",
    UK: "UK",
    LONDON: "London",
    IRELAND: "Ireland",
  },
  LocationEnums: {
    seekLocations: "seekLocations",
    seekStateLocations: "seekStateLocations",
    seekSubLocations: "seekSubLocations",
    seekAreas: "seekAreas",
    trademeLocations: "trademeLocations",
    trademeSubLocations: "trademeSubLocations",
    trademeAreas: "trademeAreas",
  },
  EnumDefinition: {
    displayName: "displayName",
  },
  EmailType: {
    UNKNOWN: 0,
    SHORTLIST: 1,
    INTERVIEW: 2,
  },
  ApplyProcess: {
    STANDARD: 0,
    BASIC: 1,
  },
  ApplicationStatus: {
    Default: 0,
    Pending: 1,
    SeekPending: 2,
    SeekCompleted: 3,
    Other: 4,
    MovedFrom: 5,
    MovedTo: 6,
  },
  GraphQL: {
    seekUrl: "https://graphql.seek.com/graphql",
  },
  AssessmentStatus: {
    Started: 0,
    InProgress: 1,
    OptedOut: 2,
    Completed: 3,
    Cancelled: 4,
    Error: 5,
  },
  JobBoardPublishedStatus: {
    DEFAULT: 0,
    PUBLISHED: 1,
    ERROR: 2,
    WITHDRAWN: 3,
    EXPIRED: 4,
  },
  EmailCategory: {
    INVITESTATE: "invite",
    GENERALSTATE: "general",
    REVIEWEMPDOCSSTATE: "reviewempdocs",
    ACKNOWLEDGEMENTSTANDARD: "applicationacknowledgementstandard",
    ACKNOWLEDGEMENTQUICK: "applicationacknowledgementquick",
    CONFIDENTIALANSWERSALERT: "confidentialquestionnaire",
  },
  HrIntegrationType: {
    NONE: 0,
    BAMBOOHR: 1,
    INTELLIHR: 2,
    BESTSTART: 3
  },
  ApplicationProcess: {
    RESUME_COMPULSORY: 1,
    BASIC_APPLY: 2,
    CONFIDENTIAL_INFO: 3,
    COVERLETTER_COMPULSORY: 4,
  },
  JobCurrentState: {
    DRAFT: "draft",
    ACTIVE: "active",
    ARCHIVE: "archive",
  },
  JobEditJobState: {
    CREATE: "create",
    DRAFT: "draft",
    EDIT: "edit",
    REOPEN: "reopen",
    UNKNOWN: "unknown",
  },
  JumpReadyCategories: {
    DEFAULT: 0,
    LESS_LIKELY: 1,
    MODERATE: 2,
    MOST_LIKELY: 3,
  },
  CountryCodes: {
    ANZ: "ANZ",
    AU: "AU",
    NZ: "NZ",
    US: "US",
  },
  SearchFilters: {
    DateBoundary: {
      Before: "before",
      Since: "since",
    },
    Keys: {
      Companies: "companies",
      JumpReadyCategory: "jumpReadyCategory",
      Keywords: "keywords",
      LastModifiedDate: "last_modified_date",
      Location: { Countries: "countries", Cities: "cities", Name: "location", States: "states" },
      Skills: "skills",
      SocialProfileLinkNames: "profile_social_link_names",
      Tags: "tags",
      Titles: "titles",
      Seniorities: "seniorities",
    },
    Recency: {
      Any: "any",
      Current: "current",
      Previous: "previous",
    },
    Tags: {
      Source: {
        Profile: "profile",
        Resume: "resume",
      },
    },
  },
  BundleStage: {
    HISTORYCHECK: 0,
    PAYPARITYCHECK: 1,
    SAFETYCHECK: 2,
    HIREDCHECK: 3,
  },
};
