import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, concat } from "@apollo/client";
import { ConstValues } from "utils/constValues";

const httpLink = new HttpLink({ uri: ConstValues.GraphQL.seekUrl });

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${JSON.parse(localStorage.getItem("browserToken")).data.access_token}`,
    },
  }));
  return forward(operation);
});

const apolloClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
});
export default apolloClient;
