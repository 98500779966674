import React, { Suspense, lazy, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import LoadingWidget from "../widgets/loader";
import { useKeycloak } from "@react-keycloak/web";
import { useSetRecoilState } from "recoil";
import { errorState } from "recoil/error/atom";
import LocaleContext from "../contexts/LocaleContext";
import i18n from 'i18next';
const ProtectedRoutes = lazy(() => import("./protectedRoutes"));
// const Login = lazy(() => import("../pages/login"));
const ErrorBoundary = lazy(() => import("../ErrorBoundary"));

const Routes = (props) => {
  // Using Object destructuring
  const { keycloak } = useKeycloak();
  const popError = useSetRecoilState(errorState);
  const [locale, setLocale] = useState(i18n.language);
  
  return (
    <Router>
      <LocaleContext.Provider value={{ locale, setLocale }}>
        <Suspense
          fallback={
            <div style={{ textAlign: "center", padding: "5rem" }}>
              <LoadingWidget color="grey" />
            </div>
          }>
          <ErrorBoundary popError={popError}>
            {!!keycloak.authenticated && <Route path="/*" render={() => <ProtectedRoutes {...props} />} />}
            {/* {!props.isAuthenticated ? ( */}
            {/* <Prompt
              when={shouldBlockNavigation}
              message="You have unsaved changes, are you sure you want to leave?"
            /> */}
            {/* {true ? (
              <>
                <Redirect to={{ pathname: "/login" }} />
                <Route path="/login">
                  <Login />
                </Route>
              </>
            ) : (
              <Route path="/*" render={() => <ProtectedRoutes {...props} />} />
            )} */}
          </ErrorBoundary>
        </Suspense>
      </LocaleContext.Provider>
    </Router>
  );
};

export default Routes;
